import { handleChatBar, handleCloseMsg, handleConfirmLinks, handleMobileNav, handleNavLinks, revealPwd } from "./_api/api.js";

// common JS
try {
    handleCloseMsg();
    handleConfirmLinks();
    revealPwd();
    handleChatBar();
} catch (error) {
    console.error('common JS: ', error);
}

// header related
try {
    handleNavLinks();
    handleMobileNav();
} catch (error) {
    console.error('common JS - header: ', error);
}